export const FEATURE_TOGGLE_V2_PROJECT_NAME = "antifraud";

export const TOGGLE_LIST = {
  DOCUMENT_KEY: "_admin_antifraud_document_key",
  LIST_PAGE_EMAIL_KEY: "_admin_antifraud_email_key",
  CREATE_PAGE_EMAIL_KEY: "_admin_antifraud_create_page_email_key",
  PHONE_KEY: "_admin_antifraud_phone_key",
  DEVICE_KEY: "_admin_antifraud_device_key",
  DEVICE_AND_EMAIL_KEY: "_admin_antifraud_device_email_key",
  SEARCH_FILTER: "_admin_antifraud_search_filter",
  ANTIFRAUD_ORCH_ENDPOINT: "_admin_antifraud_orch_endpoint",
};
