// URLs from the Admin Orchestrator
export const OLD_ORCHESTRATOR_LOWER_ENV_URL =
  "https://services.dtc.ENV.mybees-platform.dev/mandarinia/admin/COUNTRY/es-hn/graphql";
export const OLD_ORCHESTRATOR_PROD_URL =
  "https://services.dtc.mybees-platform.com/mandarinia/admin/COUNTRY/es-hn/graphql";

// URLs from the Antifraud Orchestrator
export const ORCHESTRATOR_LOWER_ENV_URL =
  "https://services.dtc.ENV.mybees-platform.dev/mandarinia/anti-fraud/COUNTRY/es-hn/graphql";
export const ORCHESTRATOR_PROD_URL =
  "https://services.dtc.mybees-platform.com/mandarinia/anti-fraud/COUNTRY/es-hn/graphql";
