import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@hexa-ui/theme";
import { createFeatureToggleServiceV2 } from "admin-portal-shared-services";
import { BrowserRouter } from "react-router-dom";
import { getApolloClient } from "./Api/graphql/graphql";
import { EnvConfig, EnvProvider } from "./components";
import AppBar from "./components/AppBar/AppBar";
import FooterVersion from "./components/FooterVersion/FooterVersion";
import LoadingPage from "./components/LoadingPage/LoadingPage";
import {
  FEATURE_TOGGLE_V2_PROJECT_NAME,
  TOGGLE_LIST,
} from "./config/featureToggles";
import useFeatureToggle from "./hooks/useFeatureToggle/useFeatureToggle";
import { IntlProvider } from "./i18n";
import { getInitialCountry } from "./utils/AppUtils";

export default function App(props: Readonly<EnvConfig>) {
  const { OPTIMIZELY_KEY } = props;
  createFeatureToggleServiceV2(
    FEATURE_TOGGLE_V2_PROJECT_NAME,
    OPTIMIZELY_KEY || ""
  );

  const antifraudOrchEndpointToggle = useFeatureToggle(
    TOGGLE_LIST.ANTIFRAUD_ORCH_ENDPOINT
  );

  const client = getApolloClient(
    getInitialCountry(props),
    antifraudOrchEndpointToggle
  );

  if (antifraudOrchEndpointToggle === undefined) {
    return <LoadingPage />;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme="tadaPrimary">
        <EnvProvider env={props}>
          <IntlProvider>
            <BrowserRouter>
              <AppBar />
              <FooterVersion />
            </BrowserRouter>
          </IntlProvider>
        </EnvProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
