import { styled } from '@hexa-ui/theme';

export const FooterVersionContainer = styled('footer', {
  marginLeft: '5rem',
  paddingLeft: '1.5rem',
  paddingBottom: '1.5rem',

  p: {
    marginBottom: '5px',  
    color: 'rgba(20, 20, 20, 0.32)',
    fontFamily: 'Roboto',
  },
});
