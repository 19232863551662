import { LoadingBuzz } from "@hexa-ui/components";
import { Container } from "./LoadingPage.styles";

const LoadingPage = () => {
  return (
    <Container data-testid="loading-page">
      <LoadingBuzz />
    </Container>
  );
};

export default LoadingPage;
