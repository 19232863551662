import { useEffect, useState } from "react";
import {
  useAuthenticationService,
  useFeatureToggleServiceV2,
} from "admin-portal-shared-services";
import { FEATURE_TOGGLE_V2_PROJECT_NAME } from "@/config/featureToggles";

export default function useFeatureToggle(
  featureToggleArg: string,
  country?: string,
): boolean | undefined {
  const [toggle, setToggle] = useState<boolean | undefined>(undefined);
  const authentication = useAuthenticationService();
  const featureToggleInstance = useFeatureToggleServiceV2(
    FEATURE_TOGGLE_V2_PROJECT_NAME,
  );

  useEffect(() => {
    async function getFeatureToggle() {
      await featureToggleInstance.onReady();

      const isFeatureEnabled: boolean =
        await featureToggleInstance.isFeatureEnabledAudiences(
          featureToggleArg,
          authentication.getUserId(),
          !!country ? { country_code: country } : {},
        );

      setToggle(isFeatureEnabled);
    }

    getFeatureToggle();
  }, [featureToggleArg, country, authentication, featureToggleInstance]);

  return toggle;
}
