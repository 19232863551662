import { INewRestrictionSchema } from "@/types/restriction.types";

export const defaultDevtoolsOptions = {
    name: 'Antifraud - Restriction List',
    enabled: localStorage.getItem('devtools') === 'true',
};

export const emptyNewRestriction: INewRestrictionSchema = {
    banned: true,
    created_by: '',
    key: undefined,
    reason: undefined,
    services: [],
    value: '',
    email_restrictions: []
}
