import { Breadcrumb } from '@hexa-ui/components';
import React, { createContext, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

export interface BreadcrumbItem {
  name: string;
  active?: boolean;
  href: string;
}

export type BreadcrumbProps = {
  items: BreadcrumbItem[];
  setItems: (items: BreadcrumbItem[]) => void;
};

const defaultInitialValue: BreadcrumbProps = {
  items: [],
  setItems: () => { },
};

export const BreadcrumbContext = createContext(defaultInitialValue);
export const useBreadcrumbContext = () => useContext(BreadcrumbContext);

export default function BreadcrumbProvider({ children }: React.PropsWithChildren<{}>) {
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

  return (
    <BreadcrumbContext.Provider value={{ items: breadcrumbItems, setItems: setBreadcrumbItems }}>
      <Breadcrumb.Root>
        <Breadcrumb.HomeItem asChild>
          <Link to='/antifraud' />
        </Breadcrumb.HomeItem>
        {breadcrumbItems.map((item) => (
          <Breadcrumb.Item key={item.name} asChild isCurrentPage={item.active}>
            <Link to={item.href}>{item.name}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb.Root>
      {children}
    </BreadcrumbContext.Provider>
  );
}
